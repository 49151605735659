import {HttpClient} from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {forkJoin, merge, Observable, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs-compat/operator/takeUntil';
import {
    delay,
    exhaustMap,
    filter,
    first,
    map,
    shareReplay,
    startWith,
    switchMap,
    switchMapTo,
    take,
    tap
} from 'rxjs/operators';
import {ModalService} from 'shared/modules/modal/modal.service';
import {NavbarContentService} from 'shared/modules/navigation/navbar-content.service';
import {ColumnConfig} from 'shared/modules/table/table-config.model';
import {
    ButtonClickedEvent,
    RowButton,
    SelectedRows
} from 'shared/modules/table/table-with-controls/table-with-controls.service';
import {Utils} from 'shared/utils/utils';
import {BookingService} from '../booking.service';
import {TimeFromTimeToCell} from '../time-from-time-to-cell/time-from-time-to-cell';

export enum DaysOfWeek {
    Понедельник = 1,
    Вторник,
    Среда,
    Четверг,
    Пятница,
    Суббота,
    Воскресение,
}

/*tslint:disable interface-over-type-literal*/
export type FormlyValidator = { expression: (c: UntypedFormControl) => boolean, message: () => string };

export const timeToMinutesFromMidnight = (d: Date) => {
    const [hours, minutes] = [d.getHours(), d.getMinutes()];
    return hours * 60 + minutes;
};
export const minutesFromMidnightToTime = (n: number) => {
    const d = new Date;
    d.setHours(Math.floor(n / 60), n % 60);
    return d;
};

export function BookingTimeValidator(min: number, max: number, step: number): { [name: string]: FormlyValidator } {
    return {
        notInRange: {
            expression: (c) => {
                if (!c.value) {
                    return true;
                }
                const v = timeToMinutesFromMidnight(c.value);
                return !(v > max || v < min);
            },
            message: () => 'Время находится за пределами интервала бронирования',
        },
        notInStep: {
            expression: (c) => {
                if (!c.value) {
                    return true;
                }
                const v = timeToMinutesFromMidnight(c.value);
                return v % step === 0;
            },
            message: () => `Время должно быть кратно ${step} минутам`,
        },
    };
}

export interface TemplateForm {
    place_id: number;
    weekdays: number[];
    period: Date[];
    time_from: Date;
    time_to: Date;
    comment?: string;
}


export interface TemplateRequest {
    place_id: number;
    date_from: string;
    date_to: string;
    weekday: number;
    time_from: number;
    time_to: number;
    comment: string;
}

@Component({
    selector: 'app-templates',
    templateUrl: './templates.component.html',
})
export class TemplatesComponent implements AfterViewInit, OnDestroy {
    @ViewChildren('toNavbar') toNavbar: QueryList<TemplateRef<any>>;

    buttons: RowButton[] = [{class: 'fa fa-edit', hint: 'Редактировать'}];
    idsToDelete = [];
    private unsubscribe = new Subject<void>();
    reload$ = new Subject<any>();
    query$ = this.reload$.pipe(startWith(''), switchMapTo(of({url: '/api/booking/template/list', body: {}})));
    DaysOfWeek = DaysOfWeek;
    columns: ColumnConfig[] = [
        {
            name: 'Место',
            prop: 'item.place_name',
        },
        {
            name: 'День недели',
            prop: 'item.weekday',
            template: 'weekday',
        },
        {
            name: 'Время',
            component: TimeFromTimeToCell,
        },
        {
            name: 'Период',
            template: 'period',
        },
        {
            name: 'Комментарий',
            prop: 'item.comment',
        },
        {
            name: 'Пользователь',
            prop: 'item.created_by_user_name',
        },
    ];

    constructor(
        private navbarContent: NavbarContentService,
        private bookingService: BookingService,
        private modalService: ModalService,
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute,
    ) {
        this.route.params.subscribe(params => {
            const id = +(params.id || NaN);
            if (!isNaN(id)) {
                this.openModalRequest(id);
            }
        });
    }

    fields(): Observable<FormlyFieldConfig[]> {
        return this.bookingService.createTemplateInfoForm$().pipe(
            take(1),
            map(({places, minTimeFrom, maxTimeFrom, minTimeTo, maxTimeTo, step}) => [
                {
                    key: 'place_id',
                    type: 'select',
                    templateOptions: {
                        label: 'Место',
                        required: true,
                        options: places,
                    },
                },
                {
                    key: 'weekdays',
                    type: 'multiselect',
                    templateOptions: {
                        label: 'Дни недели',
                        required: true,
                        options: of(Object.keys(DaysOfWeek).filter(x => !parseInt(x, 10)).map((x, i) => ({
                            label: x,
                            value: i + 1
                        }))),
                    },
                },
                {
                    key: 'period',
                    type: 'daterange',
                    templateOptions: {
                        label: 'Период бронирования',
                        required: true,
                        placement: 'bottom',
                    },
                },
                {
                    key: 'time_from',
                    type: 'timepicker',
                    templateOptions: {
                        label: 'От',
                        stepSize: of(step * 60),
                        required: true,
                    },
                    validators: BookingTimeValidator(minTimeFrom, maxTimeFrom, step),
                },
                {
                    key: 'time_to',
                    type: 'timepicker',
                    templateOptions: {
                        label: 'До',
                        stepSize: of(step * 60),
                        required: true,
                    },
                    validators: BookingTimeValidator(minTimeTo, maxTimeTo, step),
                },
                {
                    key: 'comment',
                    type: 'textarea',
                    templateOptions: {
                        label: 'Комментарий',
                        rows: 3,
                    },
                },
            ]),
            // shareReplay(1),
        );
    }

    ngAfterViewInit() {
        this.navbarContent.setTemplates(this.toNavbar);
    }

    ngOnDestroy() {
        this.navbarContent.removeTemplate();
        // Emit something to stop all Observables
        this.unsubscribe.next();
        // Complete the notifying Observable to remove it
        this.unsubscribe.complete();
    }

    setSelections(rows: SelectedRows) {
        this.idsToDelete = Object.values(rows).map(row => ({id: row.id}));
    }

    goBack() {
        this.router.navigateByUrl('/place-booking/meeting-room');
    }

    create() {
        const onSubmit = (form: TemplateForm) => {
            return forkJoin(this.formToRequestBody(form).map(body => this.http.post('/api/booking/template/create', body)));
        };
        this.fields().pipe(
            switchMap(form => this.modalService.createForm({title: 'Создать шаблон бронирования', form, onSubmit}),
            ),
        ).subscribe(() => this.reload$.next());
    }

    update(e: ButtonClickedEvent) {
        const model = this.requestBodyToForm(e.rowData);
        this.openUpdateModal({...model, id: e.rowData.id}).subscribe();
    }

    private openModalRequest(id) {
        this.http.post<any>(`/api/booking/template/get`, {id}).pipe(
            switchMap(req => {
                const reqBody = req.data;
                const model = this.requestBodyToForm(reqBody);
                return this.openUpdateModal({...model, id: reqBody.id});
            }),
        ).subscribe();
    }

    private openUpdateModal(model) {
        const onSubmit = (form: TemplateForm) => {
            return forkJoin(this.formToRequestBody(form)
                .map(x => ({...x, ...{id: model.id}}))
                .map(body => this.http.post('/api/booking/template/update', body)));
        };

        return this.fields().pipe(
            switchMap(form => this.modalService.updateForm({
                title: 'Обновить шаблон бронирования',
                form,
                model,
                onSubmit,
            })),
            tap(() => this.reload$.next()),
            first(),
        );
    }

    delete() {
        this.modalService.confirm('Вы уверены что хотите удалить шаблон?').pipe(
            exhaustMap(() => forkJoin(this.idsToDelete.map(body => this.http.post('/api/booking/template/delete', body)))),
        ).subscribe(() => this.reload$.next());
    }

    private formToRequestBody(form: TemplateForm): TemplateRequest[] {
        return form.weekdays.map(w => ({
            comment: form.comment || '',
            weekday: w,
            place_id: form.place_id,
            time_from: timeToMinutesFromMidnight(form.time_from),
            time_to: timeToMinutesFromMidnight(form.time_to),
            date_from: Utils.dateToBackend(form.period[0]),
            date_to: Utils.dateToBackend(form.period[1]),
        }));
    }

    private requestBodyToForm(req: TemplateRequest): TemplateForm {
        return {
            place_id: req.place_id,
            period: [new Date(req.date_from), new Date(req.date_to)],
            weekdays: [req.weekday],
            time_from: minutesFromMidnightToTime(req.time_from),
            time_to: minutesFromMidnightToTime(req.time_to),
        };
    }

}
