<app-pagination-wrapper [query$]="query$">
    <app-table-with-controls [columns]="columns"
                             [buttons]="buttons"
                             (selectionChanged)="setSelections($event)"
                             (buttonClicked)="update($event)"
    >

        <ng-template templateName="period" let-data>
            {{ data.row.date_from | validDate | date }} - {{ data.row.date_to | validDate | date }}
        </ng-template>
        <ng-template templateName="weekday" let-data>
            {{ DaysOfWeek[data.value] }}
        </ng-template>

    </app-table-with-controls>
</app-pagination-wrapper>

<ng-template #toNavbar>
    <button *navbarItem="null; label: null" class="btn btn-outline-secondary" (click)="goBack()">
        <i class="fa fa-arrow-left"></i>
        Вернуться
    </button>
</ng-template>
<ng-template #toNavbar>
    <button *navbarItem="null; label: null" class="btn btn-outline-secondary" (click)="create()">
        <i class="fa fa-plus"></i>
        Добавить
    </button>
</ng-template>
<ng-template #toNavbar>
    <ng-container *ngIf="idsToDelete.length">
        <button *navbarItem="null; label: null" class="btn btn-outline-danger" (click)="delete()">
            <i class="fa fa-trash"></i>
            Удалить
        </button>
    </ng-container>
</ng-template>
