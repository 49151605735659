import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable()
export class EmployeeInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('company/employee')) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            map(response => {
                // console.log({url: request.url, data: getValueByPath(response, 'body.data')});
                return response;
            })
        );
    }
}
