import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
    answer = new Subject<boolean>();
    message: string;
    modalRef: BsModalRef;

    okButtonTitle: string;
    cancelButtonTitle: string;
    cancelButtonHide: boolean = false;
    modalTitle: string;

    constructor() {
    }

    hide() {
        console.log(1);
    }
}
