import {Component} from '@angular/core';
import {fadeAnimation} from 'shared/animations/fade.animation';
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-main-layout',
    template: `
        <app-sidebar #sidebar="sidebar"></app-sidebar>
        <div class="main-view-wrapper">
            <div class="main-view">
                <app-navbar class="shadow" (toggleSidebar)="sidebar.drawerCmp.drawer.toggle()"></app-navbar>
                <div class="router-container" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
                    <router-outlet #o="outlet"></router-outlet>
                </div>
            </div>
        </div>
    `,
    animations: [fadeAnimation],
})
export class MainLayoutComponent {

    isMobile = this.deviceService.isMobile();
    constructor(
        private deviceService: DeviceDetectorService
    ) {
    }
}
