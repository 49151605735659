import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {addDays, differenceInCalendarDays, startOfYear} from 'date-fns';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {fromPHPDate, toPHPDate} from 'shared/utils/form.utils';

export interface BusinessCalendarDayInterface {
    date: string;
    is_working: boolean;
}

export interface BusinessCalendarInterface {
    [key: number]: BusinessCalendarDayInterface;
}

@Injectable({providedIn: 'root'})
export class BusinessCalendarService {

    private businessCalendar$ = new BehaviorSubject<BusinessCalendarInterface>(undefined);

    constructor(private http: HttpClient) {
        const currentYear = (new Date()).getFullYear();
        this.loadCalendars(currentYear, currentYear + 1);
    }

    private loadCalendars(currentYear: number, nextYear: number): void {
        forkJoin([
            this.getCalendar(currentYear),
            this.getCalendar(nextYear)
        ]).subscribe(([currentYearData, nextYearData]) => {
            const mergedData = {...currentYearData, ...nextYearData};
            this.businessCalendar$.next(mergedData);
        });
    }


    private getCalendar(year: number): Observable<BusinessCalendarInterface> {
        return this.http.get<ApiResponse<BusinessCalendarInterface>>(
            `/api/v2/business-calendar-full-year/${year}`).pipe(
            map(response => response.data),
            shareReplay(),
        );
    }

    lastWorkingDayFromDate(startDate: Date, interval: number): Date {
        const businessCalendar = this.businessCalendar$.getValue();
        if (!businessCalendar) {
            return startDate;
        }

        let currentDate = startDate;
        let daysChecked = 0;

        while (interval > 0) {
            const dayOfYear = differenceInCalendarDays(currentDate, startOfYear(currentDate)) + 1;
            const currentYear = currentDate.getFullYear();
            const dayData = businessCalendar[dayOfYear];

            if (dayData && dayData.is_working) {
                interval--;
            }

            daysChecked++;
            currentDate = addDays(startDate, daysChecked);

            if (currentYear > startDate.getFullYear() + 1) {
                break;
            }
        }

        return addDays(startDate, daysChecked - 1);
    }
}
